import { useEffect, useState } from "react";
import {
  updateMedioPago,
  updatePriceChangeMedio,
} from "../Redux/Actions/presupuestoActions";
import { useDispatch } from "react-redux";
import request from "../requests/request";
import { listMedioPago } from "../requests/urls";

const useValidacionMensajeErrorAFIP = () => {
  const getMensajeErrorValidacionFactura = (msgError, clean = true) => {
    // Se limpia el mensaje, removiendo los corchetes y comillas adicionales
    const cleanedMsgError = clean ? JSON.parse(msgError)[0] : msgError;

    const expresionesRegulares = [
      /Error \d+: (.+)$/, // Error con código
      /Error AFIP:\s*(.*)/, // Error AFIP
      /Error:\s*(.*)/, // Último caso genérico
    ];

    let errorValidacionAFIP =
      expresionesRegulares.reduce((errorEncontrado, regex) => {
        if (errorEncontrado) return errorEncontrado; // Ya se encontró un error
        const match = cleanedMsgError.match(regex);
        return match && match[1] ? match[1] : null;
      }, null) || cleanedMsgError; // Si no se encuentra ningún match, retorna cleanedMsgError

    // Agregar "Error de ARCA" si el error corresponde a "Error AFIP" o "Error \d+"
    if (
      /Error AFIP:\s*/.test(cleanedMsgError) ||
      /Error \d+: (.+)$/.test(cleanedMsgError)
    ) {
      errorValidacionAFIP = `Error de ARCA: ${errorValidacionAFIP}`;
    }

    return errorValidacionAFIP;
  };

  const getDataMensaje = (msgError, expresionRegular) => {
    const resultado = msgError.match(expresionRegular);

    let data = resultado && resultado.length > 1 ? resultado[1] : null;

    return data;
  };

  const getIDVentaError = (msgError) => {
    const expresionRegular = /ID venta (\d+)/;
    return getDataMensaje(msgError, expresionRegular);
  };

  const getIDDevolucion = (msgError) => {
    const expresionRegular = /Devolución ID: (\d+)/;
    return getDataMensaje(msgError, expresionRegular);
  };

  const getIDNCError = (msgError) => {
    const expresionRegular = /ID Nota crédito (\S+)/;
    return getDataMensaje(msgError, expresionRegular);
  };

  const getIDFacturaDev = (msgError) => {
    const expresionRegular = /Nro factura (\S+)/;
    return getDataMensaje(msgError, expresionRegular);
  };

  const getIDError = (msgError) => {
    const expresionRegular = /ID: (\S+)/;
    return getDataMensaje(msgError, expresionRegular);
  };

  const getIDFacturaNC = (msgError) => {
    const regexInformacion = /Factura ID: (\S+) Nota de crédito ID: (\d+)/g;

    let match = regexInformacion.exec(msgError);
    if (match) {
      return { nroFactura: match[1], idNotaCredito: match[2] };
    }
    return { nroFactura: "", idNotaCredito: "" };
  };

  const getDataErrorVentasCAE = (msgError) => {
    let errores = msgError.split(" --- ");
    let data = [];
    errores.forEach((error) => {
      let idVenta = getIDVentaError(error);
      let mensajeError = getMensajeErrorValidacionFactura(error, false).replace(
        '"]',
        "",
      );

      data.push({ idVenta, mensajeError });
    });
    return data;
  };

  const getDataInfoErrorNC = (msgError) => {
    let errores = msgError.split(" --- ");
    let data = [];
    errores.forEach((error) => {
      let dataID = getIDFacturaNC(error);
      let mensajeError = getMensajeErrorValidacionFactura(error, false);

      data.push({ dataID, mensajeError });
    });
    return data;
  };

  const getDataInfoErrorDevolucion = (msgError) => {
    let errores = msgError.split(" --- ");
    let data = [];
    let idFactura = getIDFacturaDev(msgError);
    errores.forEach((error) => {
      let idDevolucion = getIDDevolucion(error);
      let mensajeError = getMensajeErrorValidacionFactura(error, false);

      data.push({ idDevolucion, mensajeError });
    });
    return { idFactura, data };
  };

  const getDataInfoErrorAnulacion = (msgError) => {
    let idNC = getIDError(msgError);
    let mensajeError = getMensajeErrorValidacionFactura(msgError, false);
    return { idNC, mensajeError };
  };

  return {
    getMensajeErrorValidacionFactura,
    getIDVentaError,
    getDataErrorVentasCAE,
    getIDNCError,
    getDataInfoErrorNC,
    getDataInfoErrorDevolucion,
    getDataInfoErrorAnulacion,
  };
};

export default useValidacionMensajeErrorAFIP;
