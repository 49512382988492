import React, { useEffect } from "react";
import { Accordion, Typography, AccordionDetails } from "@material-ui/core";

import { MODULE_PERMS, PERMISOS } from "../../../../../../constantes";

import Listener from "./Listener";
import ItemPerm from "./Item";

import Header from "./Header";
import useStore from "./store";

function PermsCard({
  onPermissions,
  onCuentas,
  onMediosDePago,
  permisos,
  cuentas,
  mediosDePago,
  isNewSalesPerson,
}) {
  const setPermissions = useStore((state) => state.setPermissions);
  const setCuentas = useStore((state) => state.setCuentas);
  const setMediosDePago = useStore((state) => state.setMediosDePago);

  useEffect(() => {
    if (isNewSalesPerson) {
      permisos.push(PERMISOS.VENTAS_ARTICULO_SIN_STOCK);
    }

    setPermissions(permisos);

    setCuentas(cuentas);

    setMediosDePago(mediosDePago);
  }, [isNewSalesPerson]);

  return (
    <>
      <Listener
        onPermissions={onPermissions}
        onCuentas={onCuentas}
        onMediosDePago={onMediosDePago}
      />

      {MODULE_PERMS.map((module) => (
        <Accordion
          key={module.key}
          style={{ marginBottom: 10, border: "solid 1px rgb(195, 195, 195)" }}
        >
          <Header module_key={module.key} name={module.name} />

          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography style={{ paddingBottom: 15, marginTop: -12 }}>
              Permisos específicos
            </Typography>

            {module.perms.map((perms) => (
              <ItemPerm {...perms} id_perm={perms.key} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default PermsCard;
