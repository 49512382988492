import create from "zustand";
import { MODULE_PERMS } from "../../../../../../constantes";

const useStore = create((set) => ({
  cuentas: [],
  mediosDePago: [],

  setCuentas: (cuentas) =>
    set((state) => {
      return { cuentas };
    }),

  setMediosDePago: (mediosDePago) =>
    set((state) => {
      return { mediosDePago };
    }),

  modules_selected: [],
  permissions: [],

  // Fix solo se utiliza ya que el modulo de ventas el primer modulo con subpermisos y los demas no.
  permissions_ventas: [],

  checkPerm: (key) =>
    set((state) => {
      let modules_selected = [...state.modules_selected];

      let permissions = [...state.permissions];

      const isSelected = state.permissions.includes(key);

      if (isSelected) {
        // Rule business
        // Old description of rule
        // Si se está deseleccionando "Ventas - Precio Venta" y "Ventas - Precio Costo" está seleccionado, no permitir deseleccionar "Ventas - Precio Venta"
        if (
          key === "Ventas - Precio Venta" &&
          permissions.includes("Ventas - Precio Costo")
        ) {
          return state;
        }

        permissions = permissions.filter((el) => el !== key);
      } else {
        // Rule business
        // Old description of rule
        // Si el permiso no está seleccionado, verificar si es el permiso especial "Precio Costo"
        if (key === "Ventas - Precio Costo") {
          // Rule business
          // Old description of rule
          // Activar también el permiso "Ventas - Precio Venta" si no está seleccionado
          if (!permissions.includes("Ventas - Precio Venta")) {
            permissions.push("Ventas - Precio Venta");
          }
        }

        permissions.push(key);
      }

      for (const x of MODULE_PERMS) {
        const perms_of_modules = [...new Set(x.perms.map((v) => v.key))];

        if (!perms_of_modules.includes(key)) {
          continue;
        }

        let isComplete = true;

        for (const perms of perms_of_modules) {
          if (!permissions.includes(perms)) {
            isComplete = false;

            break;
          }
        }

        if (isComplete) {
          modules_selected.push(x.key);
        } else {
          modules_selected = modules_selected.filter((el) => el !== x.key);
        }

        break;
      }

      return { permissions, modules_selected };
    }),

  selectModule: (key) =>
    set((state) => {
      let modules_selected = [...state.modules_selected];

      let permissions = [...state.permissions];

      const module = MODULE_PERMS.find((el) => el.key === key);

      const isSelected = state.modules_selected.includes(key);

      const perms_of_modules = [...new Set(module.perms.map((v) => v.key))];

      if (isSelected) {
        modules_selected = modules_selected.filter((el) => el !== key);

        permissions = permissions.filter(
          (el) => !perms_of_modules.includes(el),
        );
      } else {
        modules_selected.push(key);

        permissions.push(...perms_of_modules);
      }

      return { modules_selected, permissions };
    }),

  setPermissions: (permissions) =>
    set((state) => {
      let permissions_ventas = [];

      let modules_selected = [];

      for (const x of MODULE_PERMS) {
        const perms_of_modules = [...new Set(x.perms.map((v) => v.key))];

        let isComplete = true;

        for (const perms of perms_of_modules) {
          if (!permissions.includes(perms)) {
            isComplete = false;

            break;
          }
        }

        if (isComplete) {
          modules_selected.push(x.key);
        } else {
          modules_selected = modules_selected.filter((el) => el !== x.key);
        }

        if (x.key === "ventas") {
          permissions_ventas.push(...x.perms.map((v) => v.key));
        }
      }

      return { permissions_ventas, permissions, modules_selected };
    }),
}));

export default useStore;
