import React, { useEffect } from "react";
import useStore from "./store";

const App = ({ onPermissions, onCuentas, onMediosDePago }) => {
  const permissions = useStore((state) => state.permissions);
  const cuentas = useStore((state) => state.cuentas);
  const mediosDePago = useStore((state) => state.mediosDePago);

  useEffect(() => {
    onPermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    onCuentas(cuentas);
  }, [cuentas]);

  useEffect(() => {
    onMediosDePago(mediosDePago);
  }, [mediosDePago]);

  return <></>;
};

export default App;
