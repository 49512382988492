import React from "react";

export function formatClientes(
  empleados,
  modalModificarContraseña,
  modalModificarEmpleado,
  modalEliminarEmpelado,
) {
  return empleados.map((empleado) => ({
    idEmpleado: empleado.idEmpleado,
    nombre: `${empleado.nombre} ${empleado.apellido}`,
    username: empleado.username,
    rol: empleado.rol,
    acciones: acciones(
      modalModificarContraseña,
      modalModificarEmpleado,
      modalEliminarEmpelado,
      empleado,
    ),
  }));
}

export function acciones(
  modalModificarContraseña,
  modalModificarEmpleado,
  modalEliminarEmpelado,
  empleado,
) {
  return (
    <React.Fragment>
      <i
        style={{ cursor: "pointer" }}
        onClick={() => modalModificarContraseña(empleado)}
        className={"material-icons mr-1"}
      >
        lock
      </i>
      <i
        style={{ cursor: "pointer" }}
        onClick={() => modalModificarEmpleado(empleado)}
        className={"material-icons mr-1"}
      >
        edit
      </i>
      <i
        style={{ color: "#c4183c", cursor: "pointer" }}
        onClick={() => modalEliminarEmpelado(empleado)}
        className={"material-icons mr-1"}
      >
        delete
      </i>
    </React.Fragment>
  );
}

export function formatDataParaBack(usuario) {
  return {
    nombre: usuario.nombre,
    apellido: usuario.apellido,
    id_tipo_doc: 1,
    numero_doc: usuario.documento ? String(usuario.documento) : null,
    telefono_contacto: usuario.telefono ? String(usuario.telefono) : null,
    email: usuario.email,
    fecha_nacimiento: usuario.nacimiento ? usuario.nacimiento : null,
    id_rol: Number(usuario.rol),
    username: usuario.username,
    password: usuario.password,
    permisos: usuario.permisos,
    cuentas:
      usuario.cuentas && usuario.cuentas.length
        ? usuario.cuentas.map((cuenta) => cuenta.id)
        : [],
    medios_de_pago:
      usuario.mediosDePago && usuario.mediosDePago.length
        ? usuario.mediosDePago.map((mdp) => mdp.idMedioPago)
        : [],
  };
}
