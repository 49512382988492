import { useEffect, useState } from "react";
import {
  updateMedioPago,
  updatePriceChangeMedio,
} from "../Redux/Actions/presupuestoActions";
import { useDispatch } from "react-redux";
import request from "../requests/request";
import { listMedioPago } from "../requests/urls";

const useConfiguracionCCC = (cliente, config, medioPago, montos, detalles) => {
  const {
    activar_bloqueo_cc,
    activar_advertencia_cc,
    activar_bloqueo_cc_dias_deuda,
    activar_advertencia_cc_dias_deuda,
    dias_deuda_cliente,
    monto_deuda_maxima_cc,
    medio_pago,
  } = config;

  const [dataConfig, setDataConfig] = useState({
    bloquearCtaCte: false,
    mostrarAdvertencia: false,
    mensaje: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    getBloqueoCtaCte();
  }, [cliente, medioPago]);

  const getMediosPago = async () => {
    let medios = [];
    try {
      const response = await request({
        method: "GET",
        url: listMedioPago,
      });
      if (response.data.status !== 204) {
        medios = response.data.data;
      }
    } catch (error) {
      console.error(error);
    }

    return medios;
  };

  /**
   * Funcion que verifica los datos del cliente y de config general respecto a la cta cte.
   * Pasos:
   *
   * 1)Verifica si se debe bloquear la cta cte dependiendo de los valores de configuracion. Con que alguno bloquee ya se debe bloquear la cta cte
   * 2)Si se debe bloquear la cta cte entonces se busca por cual configuracion debe bloquear (dias o deuda)
   * 3)Verificar si supera dias de deuda
   *  a) Verifica si el cliente tiene dias de deuda maxima y si no tiene verifica si hay en config general. Si no tiene ninguno de los dos queda en null
   *  b) Si hay dias de deuda maxima verifica si el cliente supera esos dias de deuda maxima segun los dias de dueda actual
   * 4)Verificar si supera monto de deuda
   *  a) Verifica si el cliente tiene monto de deuda maxima y si no tiene verifica si hay en config general. Si no tiene ninguno de los dos queda en null
   *  b) Si hay monto de deuda maxima verifica si el cliente supera ese monto de deuda maxima segun el monto de dueda actual
   * 5)Verificar los valores de superacion de dias de deuda y monto de deuda anteriores (true si supera):
   * a) Si ambos casos son true (ambos superan) entonces debe validar si debe bloquear o mostrar advertencia segun la configuracion general
   *  a.1) Si isBloqueo es true entonces bloquea la cta cte segun quien sea el que tiene bloquear true. Primero valida por dias ya que tiene mas peso
   *  a.2) Si isBloqueo es false entonces no bloquea la cta cte pero debe mostrar advertencia y setea el mensaje correspondiente
   * b) Si alguno de los dos casos es true (uno supera) entonces debe validar si debe bloquear o mostrar advertencia segun la configuracion general
   * c) Si ninguno supera entonces no bloquea ni muestra advertencia
   *
   * @returns Setea los datos de bloquearCtaCte, mostrarAdvertencia y mensaje en dataConfig segun lo siguiente:
   * bloquearCtaCte sera true si no debe mostrar el medio cta cte
   * mostrarAdvertencia sera true si debe mostrar la advertencia
   * mensaje sera una cadena de texto de la situacion de la cta cte del cliente
   */
  const getBloqueoCtaCte = () => {
    let newBloquearCtaCte = false;
    let newMostrarAdvertencia = false;
    let newMensaje = "";

    if (cliente) {
      const { dias_deuda, deudaMaxima, dias_ultima_deuda, deuda } = cliente;
      //Determina si se debe bloquear la cta cte segun los valores de configuracion
      let isBloqueo =
        activar_bloqueo_cc_dias_deuda || activar_bloqueo_cc ? true : false;

      //Determina cual es el valor de bloqueo de la cta cte (dias o monto). Si no se bloquea la cta cte sera null
      let optionBloqueo = isBloqueo
        ? activar_bloqueo_cc_dias_deuda
          ? "Dias"
          : "Deuda"
        : null;

      let superaDias = validateDiasDeuda(dias_deuda, dias_ultima_deuda);
      let superaDeuda = validateMontoDeuda(deudaMaxima, deuda);

      if (superaDias.result && superaDeuda.result) {
        newBloquearCtaCte = isBloqueo;
        newMensaje = isBloqueo
          ? optionBloqueo === "Dias"
            ? `Cta Cte bloqueada. Se superó la cantidad de días máximos de deuda (${superaDias.dias} días)`
            : `Cta Cte bloqueada. Se superó la deuda máxima permitida ($ ${parseFloat(
                superaDeuda.monto,
              ).toLocaleString("es-AR")})`
          : `Estás incrementando la deuda de un cliente cuya deuda supera los días máximos. Días de deuda excedidos = ${
              dias_ultima_deuda - superaDias.dias
            } días.`;
        newMostrarAdvertencia = isBloqueo ? false : true;
      } else {
        if (superaDias.result) {
          newBloquearCtaCte = activar_bloqueo_cc_dias_deuda;
          newMensaje = activar_bloqueo_cc_dias_deuda
            ? `Cta Cte bloqueada. Se superó la cantidad de días máximos de deuda (${superaDias.dias} días)`
            : `Estás incrementando la deuda de un cliente cuya deuda supera los días máximos. Días de deuda excedidos = ${
                dias_ultima_deuda - superaDias.dias
              }`;
          newMostrarAdvertencia = activar_advertencia_cc_dias_deuda;
        } else if (superaDeuda.result) {
          newBloquearCtaCte = activar_bloqueo_cc;
          newMensaje = activar_bloqueo_cc
            ? `Cta Cte bloqueada. Se superó la deuda máxima permitida ($ ${parseFloat(
                superaDeuda.monto,
              ).toLocaleString("es-AR")})`
            : `Estás incrementando la deuda de un cliente que superó su límite. Deuda actual = $ ${parseFloat(
                deuda,
              ).toLocaleString("es-AR")}`;
          newMostrarAdvertencia = activar_advertencia_cc;
        } else {
          newBloquearCtaCte = false;
          newMensaje = "";
          newMostrarAdvertencia = false;
        }
      }
      if (
        medioPago[0] &&
        medioPago[0].nombre === "Cuenta Corriente" &&
        newBloquearCtaCte
      ) {
        setMedioEfectivo();
      }
      setDataConfig((prev) => ({
        ...prev,
        bloquearCtaCte: newBloquearCtaCte,
        mostrarAdvertencia: newMostrarAdvertencia,
        mensaje: newMensaje,
      }));
    } else {
      setDataConfig((prev) => ({
        ...prev,
        bloquearCtaCte: false,
        mostrarAdvertencia: false,
        mensaje: "",
      }));
    }
  };

  /**
   * Funcion que retorna el valor de si supera o no los dias de deuda maxima
   * @param {int} dias_deuda Dias configurados de dias de deuda maxima del cliente
   * @param {int} dias_ultima_deuda Dias de deuda del cliente
   * @returns {boolean} true si supera los dias de deuda maxima, false si no supera
   */
  const validateDiasDeuda = (dias_deuda, dias_ultima_deuda) => {
    //Verifica si existen dias de deuda maxima en el cliente o en la configuracion general. Si ninguno tiene dias sera null.
    //Primero valida por el cliente y luego por la configuracion general
    let diasDeuda =
      dias_deuda !== null
        ? dias_deuda
        : dias_deuda_cliente !== null
          ? dias_deuda_cliente
          : null;

    //Verifica si el cliente supera los dias de deuda maxima. Si no supera sera false.
    let superaDias = false;
    if (diasDeuda !== null) {
      superaDias = dias_ultima_deuda > diasDeuda ? true : false;
    }
    return { result: superaDias, dias: diasDeuda };
  };

  /**
   * Funcion que retorna el valor de si supera o no el monto de deuda maxima
   * @param {float} deudaMaxima Monto configurado de monto de deuda maxima del cliente
   * @param {float} deuda deuda del cliente
   * @returns {boolean} true si supera los dias de deuda maxima, false si no supera
   */
  const validateMontoDeuda = (deudaMaxima, deuda) => {
    //Verifica si existe un monto de deuda maxima en el cliente o en la configuracion general. Si ninguno tiene un monto sera null.
    //Primero valida por el cliente y luego por la configuracion general
    let montoDeuda =
      deudaMaxima > 0
        ? deudaMaxima
        : monto_deuda_maxima_cc && monto_deuda_maxima_cc > 0
          ? monto_deuda_maxima_cc
          : null;

    //Verifica si el cliente supera el monto de deuda maxima. Si no supera sera false.
    let superaDeuda =
      montoDeuda !== null
        ? deuda * -1 > Number(montoDeuda)
          ? true
          : false
        : false;

    return { result: superaDeuda, monto: montoDeuda };
  };

  const setMedioEfectivo = async () => {
    let comprobanteObjectIdx;
    let mediosPago = await getMediosPago();
    if (medio_pago) {
      comprobanteObjectIdx = mediosPago.findIndex(
        (x) => x.idMedioPago === medio_pago,
      );
    } else {
      comprobanteObjectIdx = mediosPago.findIndex(
        (x) => x.idMedioPago === "Efectivo",
      );
    }
    dispatch(
      updateMedioPago(
        mediosPago[comprobanteObjectIdx],
        montos,
        medioPago,
        null,
        1,
        false,
      ),
    );

    //Como el medio era cta cte y se cambio a efectivo se debe actualizar el precio de los detalles si el cliente es con descuento por plazos
    if (cliente && cliente.clienteDescuento.isClienteDescuento) {
      dispatch(
        updatePriceChangeMedio(
          cliente.clienteDescuento,
          detalles,
          medioPago,
          false,
        ),
      );
    }
  };

  return { configCCC: { dataConfig, getBloqueoCtaCte } };
};

export default useConfiguracionCCC;
