import { PERMISOS } from "../constantes/permisos";

export const tienePermisos = (empleado, permiso) => {
  if (!empleado) return false;
  if (!empleado.rol) return false;
  if (!empleado.permisos) return false;

  const tienePermiso = empleado.permisos.find(
    (object) => object.nombre === permiso,
  );

  if (empleado.rol.nombre === "Administrador" || tienePermiso) {
    return true;
  }

  return false;
};

export const tienePermisosMedioPago = (empleado, mediosDePago) => {
  if (!empleado) return false;
  if (!empleado.rol) return false;
  if (!empleado.permisos) return false;

  const tienePermiso = empleado.permisos.find(
    (object) => object.nombre === PERMISOS.MEDIOS_DE_PAGO,
  );

  if (tienePermiso) {
    return mediosDePago.some((mdp) =>
      empleado.mediosDePago.includes(mdp.medio_pago.idMedioPago),
    );
  }

  return true;
};
