import React from "react";
import {
  Box,
  Chip,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import useStore from "./store";

import { PERMISOS } from "../../../../../../constantes";

import SelectCuentas from "./SelectCuentas";
import SelectMediosDePago from "./SelectMediosDePago";

const ItemPerm = ({ id_perm, name, description, page, icon }) => {
  const permissions_ventas = useStore((state) => state.permissions_ventas);

  const permissions = useStore((state) => state.permissions);

  const checkPerm = useStore((state) => state.checkPerm);

  // fix temporal
  const isVenta = permissions_ventas.includes(id_perm);

  const isCuentas =
    id_perm === PERMISOS.CUENTAS && permissions.includes(id_perm);

  const isMediosDePago =
    id_perm === PERMISOS.MEDIOS_DE_PAGO && permissions.includes(id_perm);

  const handleClick = () => {
    checkPerm(id_perm);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FormControlLabel
        control={
          isVenta ? (
            <Checkbox color="primary" checked={permissions.includes(id_perm)} />
          ) : (
            <></>
          )
        }
        label={name}
        style={{ marginLeft: isVenta ? 0 : 0 }}
        onClick={handleClick}
      />

      {icon && page && description && (
        <div>
          <Box
            display="flex"
            flexDirection="row"
            style={{
              marginLeft: isVenta ? 0 : 5,
              gap: 15,
              justifyContent: "space-between",
              marginTop: -5,
            }}
          >
            <Typography style={{ fontSize: 14, opacity: "0.7" }}>
              <FiberManualRecordIcon
                style={{ fontSize: 10, marginTop: -5, marginRight: 7 }}
              />

              {description}
            </Typography>

            <Chip
              style={{
                opacity: "1",
                color: "#3f51b5",
                border: "2px solid #3f51b5",
                backgroundColor: "#ffffff",
              }}
              size="small"
              icon={icon}
              label={page}
            />
          </Box>
          {isCuentas && <SelectCuentas />}
          {isMediosDePago && <SelectMediosDePago />}
        </div>
      )}
    </div>
  );
};

export default ItemPerm;
