import request from "../requests/request";
import { listMedioPago } from "../requests/urls";
import { useEffect, useState } from "react";

export const useMedioPagos = (
  cliente = null,
  filtrarMedios = false,
  usaPermisos = false,
) => {
  const [medioPagoSeleccionado, setMedioPagoSeleccionado] = useState(null);
  const [mediosPago, setMedioPagos] = useState([]);
  const [mediosPagoCompletos, setMedioPagosCompletos] = useState([]);

  const handleGetMedioPago = async () => {
    try {
      let url = usaPermisos ? listMedioPago + `?permisos=1` : listMedioPago;
      const response = await request({
        method: "GET",
        url: url,
      });
      handleResponseGetMedioPago(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseGetMedioPago = (response) => {
    if (response.data.status !== 204) {
      let mediosPagoData = response.data.data;
      if (!cliente && filtrarMedios) {
        mediosPagoData = mediosPagoData.filter(
          (x) => x.nombre !== "Cuenta Corriente" && x.nombre !== "Cheque",
        );
      }
      if (cliente && !cliente.cuenta_corriente && filtrarMedios) {
        mediosPagoData = mediosPagoData.filter(
          (x) => x.nombre !== "Cuenta Corriente",
        );
      }
      setMedioPagos(mediosPagoData);
    }
  };

  useEffect(() => {
    handleGetMedioPago();
    handleGetMedioPagoCompleto();
  }, []);

  const handleGetMedioPagoCompleto = async () => {
    const response = await request({
      method: "GET",
      url: listMedioPago,
    });
    if (response.data.status !== 204) {
      setMedioPagosCompletos(response.data.data);
    }
  };

  return {
    mediosPago,
    medioPagoSeleccionado,
    setMedioPagoSeleccionado,
    mediosPagoCompletos,
  };
};
