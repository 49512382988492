import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";

export const FieldsResponsableMedio = ({
  medioPagoSeleccionado,
  errorMedioPago,
  setMedioPagoSeleccionado,
  mediosPago,
  dataEmpleado,
  errorEmpleado,
  isCuentaCorriente,
  hasMultipleMedioPago,
  tipoMov,
  handleChangeResponsable,
  selected,
}) => {
  // Funcion encargada de setear el medio de pago seleccionado por defecto
  useEffect(() => {
    const efectivo = mediosPago.find((x) => x.nombre === "Efectivo");
    const cuentaCorriente = mediosPago.find(
      (x) => x.nombre === "Cuenta Corriente",
    );

    if (hasMultipleMedioPago() && !isCuentaCorriente) {
      setMedioPagoSeleccionado(efectivo);
    } else if (hasMultipleMedioPago() && isCuentaCorriente) {
      if (cuentaCorriente) {
        setMedioPagoSeleccionado(cuentaCorriente);
      } else {
        setMedioPagoSeleccionado(efectivo);
      }
    } else if (!hasMultipleMedioPago()) {
      const medioPagoPorDefecto = mediosPago.find(
        (x) => x.nombre === selected.pagos_cliente[0].medio_pago.nombre,
      );
      if (medioPagoPorDefecto) {
        setMedioPagoSeleccionado(medioPagoPorDefecto);
      } else {
        setMedioPagoSeleccionado(efectivo);
      }
    }
  }, [mediosPago]);

  return (
    <>
      {((isCuentaCorriente && hasMultipleMedioPago()) ||
        !isCuentaCorriente) && (
        <Grid
          container
          alignItems="center"
          alignContent="center"
          item
          xs={12}
          md={4}
          lg={4}
          style={{ marginTop: 25 }}
        >
          <Autocomplete
            fullWidth
            options={mediosPago}
            getOptionLabel={(option) => option.nombre}
            value={medioPagoSeleccionado || null}
            onChange={(event, newValue) => setMedioPagoSeleccionado(newValue)}
            isOptionEqualToValue={(option, value) =>
              option.nombre === value.nombre
            }
            disableClearable
            disabled={hasMultipleMedioPago() && isCuentaCorriente}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Medio de pago"
                variant="outlined"
                size="small"
                error={errorMedioPago}
                helperText={
                  errorMedioPago ? "Seleccione un medio de pago." : ""
                }
              />
            )}
          />
        </Grid>
      )}

      <Grid
        container
        alignContent="center"
        alignItems="center"
        item
        xs={12}
        md={4}
        lg={4}
        style={{ marginTop: 25 }}
      >
        <Autocomplete
          value={dataEmpleado.useEmpleado.empleado}
          options={dataEmpleado.useEmpleado.empleados}
          fullWidth
          filterOptions={(options, state) => options}
          getOptionLabel={(empleado) =>
            empleado.nombre + " " + empleado.apellido
          }
          inputValue={dataEmpleado.useQueryEmpleado.query || ""}
          onInputChange={(event, value) => {
            dataEmpleado.useQueryEmpleado.setQuery(value);
          }}
          onChange={(event, value) => handleChangeResponsable(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Responsable"
              variant="outlined"
              fullWidth={true}
              error={errorEmpleado.errorResponsable}
              helperText={
                errorEmpleado.errorResponsable && "Seleccione un responsable"
              }
            />
          )}
          name="buscador_clientes"
          size="small"
        />
      </Grid>
    </>
  );
};
