import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
} from "@material-ui/core";
import { errorNotification } from "../../../components/Notifications";
import request from "../../../requests/request";
import { calculoNotaCredito } from "../../../requests/urls";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { getEnabledQuantity } from "./utils";
import ModalPrevisualizacionNotaCredito from "./ModalPrevisualizacionNotaCredito";
import CuerpoNC from "./CuerpoNC";
import { useSelector } from "react-redux";
import { useMedioPagos } from "../../../customHooks/useMedioPagos";
import { roundHalfDown } from "../../../utils/parsers";

export default function ModalNotaCredito({
  open,
  selected,
  toggle,
  getVentas,
  conComision,
  isInforme,
  setOpenBackdrop,
  setDataErrorAFIP,
  closeModal,
  setVentaSelected,
  modalPdf,
  setModalPdf,
}) {
  const { nro_factura, has_factura } = selected,
    titulo = has_factura ? "FA" : "CI",
    idVentaSelected = isInforme ? selected.id : selected.idVenta,
    numeroCompr = nro_factura || idVentaSelected;

  const [dataPrevisulizacion, setDataPrevisualizacion] = useState(null);
  const [isOpenPrevisualizacion, setOpenPrevisualizacion] = useState(false);
  const [detallesDisponibles, setDetallesDisponibles] = useState([]);
  const [tipoMov, setTipoMov] = useState(true); //false = Montos -- true = Artículos
  const [dataNCMonto, setDataNCMonto] = useState({
    monto: "",
    descripcion: "",
  });
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [errorMonto, setErrorMonto] = useState({ error: false, msg: "" });
  const [montoTotalNC, setMontoTotalNC] = useState(0);
  const [enabledNC, setEnabledNC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMedioPago, setErrorMedioPago] = useState(false);
  const { mediosPago, medioPagoSeleccionado, setMedioPagoSeleccionado } =
    useMedioPagos(selected.cliente, true, true);
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();

  const montoNotasDebito = selected.notas_debito
    ? Number(selected.notas_debito.monto_total)
    : 0;
  const montoNotasCredito = selected.nc_list.reduce(
    (total, item) => total + Number(item.monto),
    0,
  );
  const enabledAmount = roundHalfDown(
    Number(selected.montoTotal) - montoNotasCredito + montoNotasDebito,
  ); //Monto habilitado para hacer una nc

  const { impuestos, aplica_impuesto } = useSelector(
    (store) => store.loginReducer,
  );

  const devuelveTodo = () => {
    if (selected.fechaHoraAnulacion) {
      return false;
    } else {
      let montoDisponibleVenta =
        selected.montoTotal -
        montoNotasCredito -
        calcularMontoTotalImpuestos() -
        Number(dataNCMonto.monto);

      montoDisponibleVenta = roundHalfDown(montoDisponibleVenta);
      if (montoDisponibleVenta <= 0.01) {
        return true;
      }
      return false;
    }
  };

  const handleChangeCantidad = (event, index) => {
    const { value } = event.target;
    if (value >= 0) {
      const updateCantidadDevolucion = [...detallesDisponibles];
      updateCantidadDevolucion[index].cantidad_devolucion = Number(value);
      let cantHabilitada = updateCantidadDevolucion[index].cantidad_habilitada;
      let cantReal = updateCantidadDevolucion[index].cantidad_real;

      updateCantidadDevolucion[index].error = { error: false, msg: "" };

      if (cantHabilitada >= 1 && cantHabilitada !== cantReal) {
        //Valida si el valor ingresado es mayor a la cant. habilitada. La cant. habilitada tiene que ser menor a la real.
        if (Number(value) > cantHabilitada) {
          updateCantidadDevolucion[index].error = {
            error: true,
            msg: "No puede superar la cantidad habilitada.",
          };
        }
      } else {
        //Valida si el valor ingresado es mayor a la cant. real.
        if (Number(value) > cantReal) {
          updateCantidadDevolucion[index].error = {
            error: true,
            msg: "No puede superar la cantidad del detalle.",
          };
        }
      }
      let sumaMontosNC = updateCantidadDevolucion.reduce((total, d) => {
        let pUnit = Number(d.subtotal) / d.cantidad;
        return d.cantidad_devolucion * Number(pUnit) + total;
      }, 0);
      sumaMontosNC = roundHalfDown(sumaMontosNC);
      setMontoTotalNC(sumaMontosNC);
      setDetallesDisponibles(updateCantidadDevolucion);
    }
  };

  const handleSubmitNotaCredito = async () => {
    if (tipoMov) {
      const hasSomeError = detallesDisponibles.some(
        (d) => d.error.error === true,
      );
      const hasSomeCantidadDevolucion = Boolean(
        detallesDisponibles.reduce((acc, d) => d.cantidad_devolucion + acc, 0),
      );

      if (
        Number(Number(calcularMontoTotalImpuestos()).toFixed(2)) >
          Number(Number(enabledAmount).toFixed(2)) ||
        (Math.abs(enabledAmount - calcularMontoTotalImpuestos()) > 0.01 &&
          Number(Number(calcularMontoTotalImpuestos()).toFixed(2)) >
            Number(Number(enabledAmount).toFixed(2)))
      ) {
        return errorNotification(
          "El monto total de la nota de crédito no puede superar el monto habilitado.",
        );
      }

      if (!hasSomeError && hasSomeCantidadDevolucion) {
        const arrayForRequest = [];
        detallesDisponibles.forEach((d, index) => {
          if (d.cantidad_devolucion > 0)
            arrayForRequest.push({
              id_detalle_venta: d.idDetalleVenta,
              cantidad: d.cantidad_devolucion,
              descripcionPersonalizada: d.descripcionPersonalizada
                ? d.descripcionPersonalizada
                : "",
            });
        });

        await handleCalculoNotaCredito({
          array: arrayForRequest,
          devuelveTodo: devuelveTodo(),
          por_montos: false,
          monto_devolucion: 0,
        });
      } else {
        return errorNotification("Hay cantidades que no corresponden.");
      }
    } else {
      if (dataNCMonto.monto === "") {
        setErrorMonto({ error: true, msg: "Ingrese un monto" });
      } else {
        await handleCalculoNotaCredito({
          array: null,
          devuelveTodo: devuelveTodo(),
          por_montos: true,
          monto_devolucion: Number(dataNCMonto.monto).toFixed(2),
        });
      }
    }
  };

  const handleCalculoNotaCredito = async ({
    array,
    devuelveTodo,
    por_montos,
    monto_devolucion,
    index = 0,
  }) => {
    try {
      const response = await request({
        method: "PUT",
        url: calculoNotaCredito(idVentaSelected),
        data: {
          detalle_nota: array,
          nota_credito_gral: devuelveTodo,
          por_montos,
          monto_devolucion,
        },
      });
      let newData = response.data;
      if (!tipoMov) {
        console.log(newData);
        console.log(dataNCMonto);
        newData.dataNCMonto = dataNCMonto;
      }
      setDataPrevisualizacion(newData);
      setOpenPrevisualizacion(true);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 406) {
        setErrorMonto({
          error: true,
          msg: error.response.data.message,
        });
      }
    }
  };

  const handleGetDetallesDisponible = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: ` /api/venta/datos/?idVenta=${idVentaSelected}`,
      });
      const { status, data } = response;
      if (status === 200) {
        let newEnabledNC = false;
        let newDesc = data.venta.id_desc_total
          ? 1 - Number(data.venta.id_desc_total.porcentaje)
          : 1;
        const detallesParsed = data.detalles.map((d) => ({
          ...d,
          cantidad_devolucion: 0,
          error: { error: false, msg: "" },
          subtotal: Number(d.subtotal) * newDesc,
          cantidad_habilitada: getEnabledQuantity(enabledAmount, d, newDesc),
        }));
        //Valida si por lo menos un artículo tiene cantidad habilitada
        detallesParsed.map((d) => {
          if (d.cantidad_habilitada > 0) {
            newEnabledNC = true;
          }
        });
        setDetallesDisponibles(detallesParsed);
        setEnabledNC(newEnabledNC);
      } else {
        setDetallesDisponibles([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCloseModalPrevisualizacion = (event) => {
    setOpenPrevisualizacion(false);
    setDataPrevisualizacion(null);
    getVentas();
    !event && toggle();
  };

  const calcularMontoTotalImpuestos = () => {
    if (
      aplica_impuesto &&
      selected.tipo_factura &&
      (selected.tipo_factura.nombre === "A" ||
        selected.tipo_factura.nombre === "M")
    ) {
      let montoSinIva = montoTotalNC / 1.21;
      let montoImpuestos = selected.receipt.taxes.reduce(
        (acc, tax) =>
          acc + Number(((Number(tax.aliquot) / 100) * montoSinIva).toFixed(2)),
        0,
      );
      let montoConImpuestos = Number(
        (montoImpuestos + montoTotalNC).toFixed(2),
      );
      let decimalDiff = Math.abs(enabledAmount - montoConImpuestos);

      // Para evitar errores de numeros de más de 2 decimales
      // si la diferencia es menos a la unidad mas pequeña (1 centado = 0.01)
      // se devuelve el monto desponible por que lo que calcula el back
      if (decimalDiff <= 0.01) {
        return enabledAmount;
      } else {
        return montoConImpuestos;
      }
    } else {
      return montoTotalNC;
    }
  };

  // Funcion encargada de fijarse si en los pagos de la venta se encuentra cuenta corriente, si se encuentra devuelve true, de lo contrario false
  const isCuentaCorriente =
    (selected &&
      selected.pagos_cliente.some(
        (x) => x.medio_pago && x.medio_pago.nombre === "Cuenta Corriente",
      )) ||
    (selected &&
      selected.pagos_cliente.some(
        (x) =>
          x.tipo_movimiento && x.tipo_movimiento.descripcion === "Devolución",
      ))
      ? true
      : false;

  useEffect(() => {
    handleGetDetallesDisponible();
  }, []);

  useEffect(() => {
    errorResponsable && setErrorResponsable(false);
  }, [useEmpleado.empleado]);

  useEffect(() => {
    medioPagoSeleccionado && setErrorMedioPago(false);
  }, [medioPagoSeleccionado]);

  useEffect(() => {
    if (!tipoMov) {
      let copyDetalles = detallesDisponibles.slice();

      copyDetalles.map((c) => {
        c.cantidad_devolucion = 0;
        c.error = { error: false, msg: "" };
      });
      setMontoTotalNC(0);
      setDetallesDisponibles(copyDetalles);
    } else {
      setDataNCMonto({
        monto: "",
        descripcion: "",
      });
      setErrorMonto({ error: false, msg: "" });
    }
  }, [tipoMov]);

  return (
    <Dialog
      maxWidth={"md"}
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle>
        <Typography
          style={{
            fontWeight: "bolder",
            textAlign: "center",
          }}
          variant="h6"
        >
          {has_factura
            ? "Generar nota de crédito de "
            : "Generar devolución de "}
          <label className="fontBold">
            {titulo} {numeroCompr}
          </label>
        </Typography>
      </DialogTitle>
      <Divider />
      {!dataPrevisulizacion && !isOpenPrevisualizacion && (
        <>
          <DialogContent style={{ paddingBottom: 20 }}>
            <CuerpoNC
              isInforme={isInforme}
              selected={selected}
              conComision={conComision}
              movimiento={{ tipoMov, setTipoMov }}
              dataEmpleado={{ useEmpleado, useQueryEmpleado }}
              errorEmpleado={{ errorResponsable, setErrorResponsable }}
              dataNCMonto={{ dataNCMonto, setDataNCMonto }}
              errorMonto={{ errorMonto, setErrorMonto }}
              detallesDisponibles={detallesDisponibles}
              handleChangeCantidad={handleChangeCantidad}
              enabledAmount={enabledAmount}
              montoTotalNC={calcularMontoTotalImpuestos()}
              toggle={toggle}
              enabledNC={enabledNC}
              handleSubmitNotaCredito={handleSubmitNotaCredito}
              loading={loading}
              medioPagoSeleccionado={medioPagoSeleccionado}
              setMedioPagoSeleccionado={setMedioPagoSeleccionado}
              mediosPago={mediosPago}
              setErrorMedioPago={setErrorMedioPago}
              errorMedioPago={errorMedioPago}
              isPrevisualizacion={false}
              isCuentaCorriente={isCuentaCorriente}
            />
          </DialogContent>
        </>
      )}

      {dataPrevisulizacion && isOpenPrevisualizacion && (
        <ModalPrevisualizacionNotaCredito
          selected={selected}
          conComision={conComision}
          isFactura={has_factura}
          data={dataPrevisulizacion}
          open={isOpenPrevisualizacion}
          toggle={handleCloseModalPrevisualizacion}
          isInforme={isInforme}
          responsable={useEmpleado.empleado}
          tipoMov={tipoMov}
          setOpenBackdrop={setOpenBackdrop}
          medioPago={medioPagoSeleccionado}
          setDataErrorAFIP={setDataErrorAFIP}
          closeModal={closeModal}
          setVentaSelected={setVentaSelected}
          modalPdf={modalPdf}
          setModalPdf={setModalPdf}
        />
      )}
    </Dialog>
  );
}
