import moment from "moment";
import React from "react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import axios from "axios";
import {
  getTotalesMedioPago,
  listMedioPago,
  listTipoComprobante,
  getClienteSucursal,
} from "../../../requests/urls";
import request from "../../../requests/request";
import { Checkbox, MenuItem } from "@material-ui/core";
import useDisclosure from "../../../customHooks/useDisclosure";
import ModalCrearNotaDebito from "../../../components/ait-reusable/ModalCrearNotaDebito";
import { tienePermisosMedioPago } from "../../../utils/tienePermisos";
import { useSelector } from "react-redux";

const isChecked = (factura, selection) => {
  let exist = selection.find((x) => x.id === factura.id);
  if (exist) {
    return true;
  } else {
    return false;
  }
};

const handleSelectOne = (factura, facturas, selection, setSelection) => {
  let exist = selection.some((x) => x.id === factura.id);

  if (exist) {
    setSelection([...selection.filter((x) => x.id !== factura.id)]);
  } else {
    let newSelection = facturas.filter((x) => x.id === factura.id);
    setSelection(selection.concat(newSelection));
  }
};

const isDisabledCheck = (venta, selection) => {
  //Se deshabilitara si la venta es:
  // 1. Es una Factura
  // 2. Tiene devoluciones realizadas
  // 3. Es con descuentos por plazo
  // 4. No esta pagada totalmente
  let value = false;
  if (venta.has_factura) {
    value = true;
  } else if (venta.nc_list.length) {
    value = true;
  } else if (venta.recargo_cc) {
    value = true;
  } else if (venta.deuda && venta.deuda > 0) {
    value = true;
  }

  return value;
};

export function formatVentas(
  ventas,
  setComprobanteDetalle,
  handleOpenModalAnular,
  setVenta,
  toggleModalValidate,
  toggleModalComprobanteAFactura,
  handleReCrearVenta,
  toggleModalDetalleAnular,
  generarDevolucion,
  generarPdf,
  config,
  setNewClient,
  abrirModalFacturarATerceros,
  handleSendMail,
  handleOpenModalSeleccionar,
  generarPdfConNotaCredito,
  handleOpenModalComprobante,
  handleOpenModalWhatsApp,
  handleModalFechaVencimiento,
  isVendedor,
  perfil,
  aplica_impuesto,
  selection,
  setSelection,
  getDataFacturaAgrupada,
  getVentas,
  handleImprimirRotulo,
) {
  const { empleado } = useSelector((state) => state.loginReducer);

  return ventas.map((venta) => ({
    checkbox: (
      <Checkbox
        color="primary"
        checked={isChecked(venta, selection)}
        onChange={() => handleSelectOne(venta, ventas, selection, setSelection)}
        className="fontBold"
        disabled={isDisabledCheck(venta, selection)}
      />
    ),
    fecha: (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "0rem" }}>
          {moment(venta.fechaHora).format("DD-MM")}
        </label>
        <label style={{ marginBottom: "0rem" }}>
          {moment(venta.fechaHora).format("HH:mm")}
        </label>
      </div>
    ),
    cliente: SpanCliente(
      (venta.cliente && venta.cliente.razonSocial) || "S/E",
      25,
    ),
    tipoReferencia: BadgeComprobante(venta),
    nroReferencia: (
      <div>
        <label className="fontBold" style={{ fontSize: 15 }}>
          N°
        </label>{" "}
        {venta.nro_factura ? venta.nro_factura : venta.id}
      </div>
    ),
    observacion: venta.descripcion ? (
      <div
        style={{
          maxWidth: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {venta.descripcion.substr(0, 35).trim() +
          (venta.descrpcion > 35 ? "..." : "")}
        <div style={{ paddingLeft: 2 }}>
          <TooltipMoreInfoMaterial
            titleTooltip={
              venta.tipo_venta ? venta.tipo_venta.descripcion : null
            }
            position={"right"}
            color={"rgb(8, 150, 255)"}
          />
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        --
        <div style={{ paddingLeft: 2 }}>
          <TooltipMoreInfoMaterial
            titleTooltip={
              venta.tipo_venta ? venta.tipo_venta.descripcion : null
            }
            position={"right"}
            color={"rgb(8, 150, 255)"}
          />
        </div>
      </div>
    ),
    monto: `$ ${Number(venta.montoTotal).toLocaleString("es-AR")}`,
    opciones: ActionTable(
      venta,
      setComprobanteDetalle,
      handleOpenModalAnular,
      setVenta,
      toggleModalValidate,
      toggleModalComprobanteAFactura,
      handleReCrearVenta,
      toggleModalDetalleAnular,
      generarDevolucion,
      generarPdf,
      config,
      setNewClient,
      abrirModalFacturarATerceros,
      handleSendMail,
      handleOpenModalSeleccionar,
      generarPdfConNotaCredito,
      handleOpenModalComprobante,
      handleOpenModalWhatsApp,
      handleModalFechaVencimiento,
      isVendedor,
      perfil,
      aplica_impuesto,
      getDataFacturaAgrupada,
      getVentas,
      handleImprimirRotulo,
      tienePermisosMedioPago(empleado, venta.pagos_cliente),
    ),
  }));
}

export function formatTotales({
  total_efectivo,
  total_cuenta_corriente,
  total_credito,
  total_debito,
  total_transferencia,
  total_cheque,
}) {
  return [
    {
      concepto: "Efectivo",
      total: total_efectivo
        ? Number(total_efectivo).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Cta. Cte",
      total: total_cuenta_corriente
        ? Number(total_cuenta_corriente).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Crédito",
      total: total_credito ? Number(total_credito).toLocaleString("es-AR") : 0,
    },
    {
      concepto: "Débito",
      total: total_debito ? Number(total_debito).toLocaleString("es-AR") : 0,
    },
    {
      concepto: "Transf. bancaria",
      total: total_transferencia
        ? Number(total_transferencia).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Cheque",
      total: total_cheque ? Number(total_cheque).toLocaleString("es-AR") : 0,
    },
  ];
}

export function formatTotalesConMultipleMedio({
  total_efectivo,
  total_cuenta_corriente,
  total_credito,
  total_debito,
  total_transferencia,
  total_cheque,
  total_multiple_medio,
}) {
  return [
    {
      concepto: "Efectivo",
      total: total_efectivo
        ? Number(total_efectivo).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Cta. Cte",
      total: total_cuenta_corriente
        ? Number(total_cuenta_corriente).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Crédito",
      total: total_credito ? Number(total_credito).toLocaleString("es-AR") : 0,
    },
    {
      concepto: "Débito",
      total: total_debito ? Number(total_debito).toLocaleString("es-AR") : 0,
    },
    {
      concepto: "Transf. bancaria",
      total: total_transferencia
        ? Number(total_transferencia).toLocaleString("es-AR")
        : 0,
    },
    {
      concepto: "Cheque",
      total: total_cheque ? Number(total_cheque).toLocaleString("es-AR") : 0,
    },
    {
      concepto: "Múltiples medios",
      total: total_multiple_medio
        ? Number(total_multiple_medio).toLocaleString("es-AR")
        : 0,
    },
  ];
}

function SpanCliente(cliente, maxLenght) {
  return (
    <span title={cliente}>
      {cliente.length > maxLenght
        ? cliente.slice(0, maxLenght).toUpperCase() + "..."
        : cliente.toUpperCase()}
    </span>
  );
}

const tipoComprobanteFactura = (venta) => {
  if (venta.tipo_comprobante.nombre === "Comprobante interno") {
    return "CI";
  } else if (venta.tipo_factura.nombre === "B") {
    return "FB";
  } else if (
    venta.tipo_factura.nombre === "A" &&
    !venta.tipo_comprobante.nombre === "Factura"
  ) {
  } else if (
    venta.tipo_factura.nombre === "A" &&
    venta.tipo_comprobante.nombre === "Factura"
  ) {
    return "FA";
  } else if (
    venta.tipo_factura.nombre === "A" &&
    venta.tipo_comprobante.nombre === "FCEM A"
  ) {
    return "FCEM A";
  } else if (venta.tipo_factura.nombre === "C") {
    return "FC";
  } else if (venta.tipo_factura.nombre === "M") {
    return "FM";
  }
};

function BadgeComprobante(venta) {
  let tieneMontoPorDevolver =
    venta.montoTotal -
    venta.nc_list.reduce((total, item) => total + Number(item.monto), 0) +
    (venta.notas_debito ? venta.notas_debito.monto_total : 0);
  tieneMontoPorDevolver = Number(tieneMontoPorDevolver.toFixed(2));
  let message = "Efectiva";
  let color = "green";
  if (venta.fechaHoraAnulacion) {
    color = "red";
    message = `Anulada el ${moment(venta.fechaHoraAnulacion).format("DD-MM")}`;
  } else if (venta.has_factura) {
    color = venta.is_validated ? "green" : "#F0E68C";
    message = venta.is_validated ? "Efectiva" : "Factura pendiente";
  }
  if (
    tieneMontoPorDevolver > 0 &&
    venta.fechaHoraAnulacion &&
    venta.notas_debito
  ) {
    color = "#ff8100";
    message =
      "La venta esta anulada, pero factura posee notas de débito vigentes";
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <label className="fontBold" style={{ fontSize: 15 }}>
          {tipoComprobanteFactura(venta)}
        </label>
        {venta.is_agrupada && (
          <div style={{ paddingLeft: 2, paddingBottom: 6 }}>
            <TooltipMoreInfoMaterial
              position={"right"}
              titleTooltip={
                <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                  Factura agrupada
                </h6>
              }
              color={"blue"}
            />
          </div>
        )}
      </div>

      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            {message}
          </h6>
        }
        color={color}
      />
    </div>
  );
}

function ActionTable(
  venta,
  setComprobanteDetalle,
  handleOpenModalAnular,
  setVenta,
  toggleModalValidate,
  toggleModalComprobanteAFactura,
  handleReCrearVenta,
  toggleModalDetalleAnular,
  generarDevolucion,
  generarPdf,
  config,
  setNewClient,
  abrirModalFacturarATerceros,
  handleSendMail,
  handleOpenModalSeleccionar,
  generarPdfConNotaCredito,
  handleOpenModalComprobante,
  handleOpenModalWhatsApp,
  handleModalFechaVencimiento,
  isVendedor,
  perfil,
  aplica_impuesto,
  getDataFacturaAgrupada,
  getVentas,
  handleImprimirRotulo,
  tienePermisosMedioDePago,
) {
  const validateFcemA = venta.montoTotal < 500000 || venta.total_nc === 0;

  return (
    <MenuB>
      <MenuItem onClick={() => setComprobanteDetalle(venta)}>
        Ver detalle
      </MenuItem>
      {!venta.has_factura &&
      !venta.fechaHoraAnulacion &&
      !aplica_impuesto &&
      validateFcemA ? (
        <MenuItem onClick={() => toggleModalComprobanteAFactura(venta)}>
          Generar factura
        </MenuItem>
      ) : null}
      {!isVendedor(perfil) &&
        venta.tipo_comprobante.nombre !== "FCEM A" &&
        !venta.fechaHoraAnulacion &&
        venta.has_factura && (
          <MenuItem onClick={() => handleModalFechaVencimiento(venta)}>
            Cambiar fecha de vencimiento
          </MenuItem>
        )}
      {venta.fechaHoraAnulacion === null &&
      venta.nota_credito === false &&
      tienePermisosMedioDePago ? (
        <MenuItem
          onClick={() => {
            handleOpenModalAnular();
            getDataFacturaAgrupada(venta);
          }}
        >
          Anular
        </MenuItem>
      ) : venta.nota_credito === false && tienePermisosMedioDePago ? (
        <MenuItem
          onClick={() => {
            handleOpenModalAnular();
            getDataFacturaAgrupada(venta);
          }}
        >
          Anular
        </MenuItem>
      ) : null}

      {venta.has_factura && !venta.is_validated && (
        <MenuItem
          onClick={() => {
            toggleModalValidate(venta);
          }}
        >
          Generar CAE AFIP
        </MenuItem>
      )}
      {!venta.has_factura && !venta.fechaHoraAnulacion && !aplica_impuesto && (
        <MenuItem
          onClick={() => {
            abrirModalFacturarATerceros(venta);
          }}
        >
          Facturar a terceros
        </MenuItem>
      )}

      <MenuItem onClick={() => handleOpenModalComprobante(venta)}>
        Imprimir Comprobante
      </MenuItem>

      {venta.nota_credito && (
        <MenuItem onClick={() => handleOpenModalSeleccionar(venta)}>
          {venta.has_factura
            ? "Imprimir Nota de Crédito"
            : "Imprimir Devolución"}
        </MenuItem>
      )}

      {venta.tipo_comprobante.nombre !== "FCEM A" ? (
        <MenuItem onClick={() => handleSendMail(venta.id, venta.cliente)}>
          Enviar EMAIL
        </MenuItem>
      ) : null}

      <GenerarNotaCreditoParcial
        venta={venta}
        toggleModalDetalleAnular={toggleModalDetalleAnular}
      />

      <GenerarNotaDebito venta={venta} getVentas={getVentas} />

      {!venta.has_factura &&
        !venta.fechaHoraAnulacion &&
        tienePermisosMedioDePago && (
          <MenuItem onClick={() => generarDevolucion(venta)}>
            Generar Devolución
          </MenuItem>
        )}

      {venta.tipo_comprobante.nombre !== "FCEM A" ? (
        <MenuItem onClick={() => handleOpenModalWhatsApp(venta)}>
          Enviar por WhatsApp
        </MenuItem>
      ) : null}

      <MenuItem
        onClick={() => recrearVenta(handleReCrearVenta, setNewClient, venta)}
      >
        Recrear venta
      </MenuItem>

      <MenuItem onClick={() => handleImprimirRotulo(venta)}>
        Imprimir Rótulo de Envío
      </MenuItem>
    </MenuB>
  );
}

const GenerarNotaCreditoParcial = ({ venta, toggleModalDetalleAnular }) => {
  const tieneMontoPorDevolver =
    venta.montoTotal -
    venta.nc_list.reduce((total, item) => total + Number(item.monto), 0) +
    (venta.notas_debito ? venta.notas_debito.monto_total : 0);
  if (
    venta.tipo_comprobante.nombre !== "FCEM A" &&
    venta.has_factura &&
    venta.is_validated &&
    (!venta.fechaHoraAnulacion || tieneMontoPorDevolver > 0) &&
    !venta.is_agrupada
  ) {
    return (
      <MenuItem onClick={() => toggleModalDetalleAnular(venta)}>
        Generar Nota de Crédito Parcial
      </MenuItem>
    );
  }

  return null;
};

const GenerarNotaDebito = ({ venta, getVentas = async () => {} }) => {
  let tieneComision = venta.pagos_cliente.some(
    (pc) =>
      pc.tipo_movimiento && pc.tipo_movimiento.nombre === "Pagos de Comisiones",
  );
  let pagosClienteConMediosDePago = venta.pagos_cliente.filter(
    (pc) => pc.medio_pago,
  );
  let tieneMedioPagoCuentaCorriente = pagosClienteConMediosDePago.some(
    (mp) => mp.medio_pago.tipo === "Cuenta Corriente",
  );

  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const isFCEM =
    venta.tipo_comprobante && venta.tipo_comprobante.nombre === "FCEM A";

  if (
    isFCEM ||
    !venta.has_factura ||
    !venta.is_validated ||
    venta.is_agrupada ||
    tieneMedioPagoCuentaCorriente ||
    venta.fechaHoraAnulacion ||
    tieneComision ||
    venta.recargo_cc
  ) {
    return null;
  }

  /**Se ejecutará luego de que se cree la nota de débito */
  const onSubmit = async () => {
    await getVentas();
  };

  return (
    <>
      <MenuItem onClick={openModal}>Generar Nota de Débito</MenuItem>
      {openedModal && (
        <ModalCrearNotaDebito
          open={openedModal}
          venta={venta}
          handleClose={closeModal}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

const recrearVenta = async (handleReCrearVenta, setNewClient, venta) => {
  const idCliente = venta.cliente && venta.cliente.id;
  if (idCliente) {
    try {
      const response = await request({
        method: "GET",
        url: getClienteSucursal(idCliente),
      });
      let result = response.data.data;
      let dataClient = {
        idClienteDeSucursal: result.idClienteDeSucursal,
        deuda: result.deuda,
        monto_billetera: result.monto_billetera,
        comision: result.comision,
        deudaMaxima: result.idCliente.deudaMaxima,
        descuento_prefijado: result.descuento_prefijado,
        cliente: {
          numeroDocumento: result.idCliente.numeroDocumento,
          razonSocial: result.idCliente.razonSocial,
          CUIT: result.idCliente.CUIT,
          estadoTributario: { nombre: venta.cliente.estadoTributario },
          provincia: result.idCliente.provincia,
          esta_grabado_iibb: result.idCliente.esta_grabado_iibb,
        },
        recargo: result.recargo,
        es_comision: result.es_comision,
        cuenta_corriente: Boolean(result.cuenta_corriente),
        impuestos: result.impuestos.filter((imp) => imp.activo === true),
      };

      setNewClient(dataClient, false);
      handleReCrearVenta(venta.id);
    } catch (error) {
      console.error(error);
    }
  } else {
    handleReCrearVenta(venta.id);
  }
};

export const getData = async (
  dates,
  tipoComprobante,
  medioPago,
  cliente,
  notaCredito,
  empleado,
  venta,
  id_tarjeta,
  articulo = "",
  tipo_venta,
  tipoCliente,
  pendienteCae,
  incluirTodasLasDevolucionesDePeriodo = null,
  provincia = null,
  transporte,
) => {
  const token = localStorage.getItem("token");
  const defaultHeaders = { Authorization: `Token ${token}` };
  let response = {};
  await axios
    .all([
      axios.get(getTotalesMedioPago, {
        headers: defaultHeaders,
        // cancelToken: cancelTokenSourceRef ? cancelTokenSourceRef.current.token : null,
        params: {
          inicio: dates.fromDate,
          fin: dates.toDate,
          tipoComprobante,
          medioPago,
          cliente,
          notaCredito,
          responsable: empleado,
          venta,
          id_tarjeta,
          articulo: articulo,
          tipo_venta: tipo_venta,
          tipo_cliente: tipoCliente,
          pendientesCae: pendienteCae,
          incluirTodasLasDevolucionesDePeriodo: Number(
            Boolean(incluirTodasLasDevolucionesDePeriodo),
          ),
          provincia: provincia,
          transporte: transporte,
        },
      }),
    ])
    .then(
      axios.spread((totalesMedioPago, totalesRecaudados) => {
        response = {
          totalesMedioPago,
        };
      }),
    )
    .catch((error) => console.error(error));
  return response;
};

export const getFilters = async () => {
  const token = localStorage.getItem("token");
  const defaultHeaders = { Authorization: `Token ${token}` };
  let response = {};
  await axios
    .all([
      axios.get(listTipoComprobante, { headers: defaultHeaders }),
      axios.get(listMedioPago, { headers: defaultHeaders }),
    ])
    .then(
      axios.spread((tiposComprobante, mediosPago) => {
        response = {
          tiposComprobante,
          mediosPago,
        };
      }),
    )
    .catch((error) => console.error(error));
  return response;
};

//Función que valida si fechaDesde y fechaHasta estan vacios, si lo estan setea su error.
export const validateFilters = (dates) => {
  let errors = {};

  if (dates.fromDate === "") {
    errors.fromDate = "Ingrese una fecha";
  }
  if (dates.toDate === "") {
    errors.toDate = "Ingrese una fecha";
  }
  return errors;
};

/**
 * Funcion encargada de fijarse si tiene pagos con cheques que se utilizaron para pagar una compra
 * @returns {boolean} true si tiene cheques utilizados, false si no tiene cheques utilizados
 */
export const tieneChequesUtilizados = (ventaSelected) => {
  let tieneCheques = false;

  ventaSelected.pagos_cliente.forEach((pago) => {
    // Si el pago es con cheque y tiene data de pago cheque compra es que ese cheque se utilizo para pagar una compra
    //TieneCheques debe ser false ya si una vez que paso a true no importa que tenga en los otros medios. No se podra anular
    if (
      pago.medio_pago &&
      pago.medio_pago.nombre === "Cheque" &&
      pago.data_pago_cheque_compra &&
      !tieneCheques
    ) {
      //Se debe validar que ese cheque que se utilizo sigue utilizado o no
      tieneCheques = pago.data_pago_cheque_compra.es_utilizado;
    }
  });

  return tieneCheques;
};
