import React from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStore from "./store";
import { useMedioPagos } from "../../../../../../customHooks/useMedioPagos";

function SelectMediosDePago() {
  const mediosDePago = useStore((state) => state.mediosDePago);

  const setMediosDePago = useStore((state) => state.setMediosDePago);

  const medioPagos = useMedioPagos();

  const handleSelectAll = () => {
    setMediosDePago(medioPagos.mediosPagoCompletos);
  };

  const handleSelect = (event, value) => {
    setMediosDePago(value);
  };

  return (
    <Box className="py-2" width="100%">
      <Autocomplete
        fullWidth
        multiple
        id="tags-outlined"
        options={medioPagos.mediosPagoCompletos}
        getOptionLabel={(options) => options.nombre}
        onSelect={() => {}}
        onChange={handleSelect}
        value={mediosDePago}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            name="mediosDePago"
            variant="outlined"
            label="Medios de Pago disponibles"
            style={{ backgroundColor: "white" }}
            // error={formik.touched.mediosDePago && Boolean(formik.errors.mediosDePago)}
          />
        )}
        size="small"
        name="buscador_cuentas"
      />
      <Box display="flex" justifyContent="flex-start">
        <Typography
          className="pt-2"
          fontSize={6}
          fontWeight={700}
          onClick={handleSelectAll}
          style={{
            color: "#3f51b5",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Agregar todas
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Typography style={{ fontSize: 14, opacity: "0.7" }}>
          Los medios de pago seleccionados serán los únicos disponibles para
          este usuario al momento de registrar ventas, presupuestos, notas de
          crédito y débito. No afecta la visibilidad de Cuentas.
        </Typography>
      </Box>
    </Box>
  );
}

export default SelectMediosDePago;
