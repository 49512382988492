import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import moment from "moment";
import React from "react";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import ButtonCancelar from "../../../components/ait-reusable/Button/ButtonCancelar";
import ButtonFormato from "../../../components/ait-reusable/Button/ButtonFormato";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import ModalDetalleConciliacionVentas from "../../../components/ait-reusable/ModalDetalleConciliacionVentas";
import useDisclosure from "../../../customHooks/useDisclosure";
import request from "../../../requests/request";
import {
  anulacionOrdenPago,
  anulacionOrdenRetiro,
  anulacionPagoCliente,
  getClienteSucursal,
  obtenerEgresos,
  obtenerIngresos,
  obtenerTotales,
  listMedioPago,
} from "../../../requests/urls";
import ModalAnularConciliacionVentas from "../../../components/ait-reusable/ModalAnularConciliacionVentas";
import ModalRehacerConciliacion from "../../../components/ait-reusable/ModalRehacerConciliacion";

export const initialResumen = [
  {
    nombre: "cobros",
    monto: 0,
    icon: <ArrowUpwardIcon style={{ color: "green" }} />,
  },
  {
    nombre: "salidas",
    monto: 0,
    icon: <ArrowDownwardIcon style={{ color: "red" }} />,
  },
  {
    nombre: "monto billetera",
    monto: 0,
    icon: <ImportExportIcon />,
  },
];

export default async function getMediosPago() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: listMedioPago,
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function deleteMov(id) {
  let result = [];
  try {
    const response = await request({
      method: "POST",
      url: anulacionPagoCliente(id),
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function deleteOrdenSelected(pagoSelected) {
  const esCarga = pagoSelected.tipo_registro === "orden_carga_saldo";
  const dataRetiro = {
    orden_retiro_saldo_id: pagoSelected.orden_retiro_saldo_id,
  };
  const dataPago = {
    orden_carga_saldo_id: pagoSelected.orden_carga_saldo_id,
  };

  let result = {};

  try {
    const response = await request({
      method: "PUT",
      url: esCarga ? anulacionOrdenPago : anulacionOrdenRetiro,
      data: esCarga ? dataPago : dataRetiro,
    });
    result = response;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function getTotales(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerTotales(idCliente),
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getdataCliente(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getClienteSucursal(idCliente),
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getIngresos(
  idCliente,
  page,
  dates,
  useEmpleado,
  tipoMovimientoSelected,
  observacionValue,
  montosValue,
) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerIngresos(idCliente),
      params: {
        page: page,
        desde: dates.fromDate,
        hasta: dates.toDate,
        empleado: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : null,
        motivo: !tipoMovimientoSelected.length
          ? null
          : tipoMovimientoSelected.map((x) => x.id),
        observacion: observacionValue,
        monto_minimo: !montosValue.montoMinimo
          ? 0
          : Number(montosValue.montoMinimo),
        monto_maximo: !montosValue.montoMaximo
          ? 999999999999999
          : Number(montosValue.montoMaximo),
      },
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getEgresos(
  idCliente,
  page,
  dates,
  useEmpleado,
  tipoMovimientoSelected,
  observacionValue,
  montosValue,
) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerEgresos(idCliente),
      params: {
        page: page,
        desde: dates.fromDate,
        hasta: dates.toDate,
        empleado: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : null,
        motivo: !tipoMovimientoSelected.length
          ? null
          : tipoMovimientoSelected.map((x) => x.id),
        observacion: observacionValue,
        monto_minimo: !montosValue.montoMinimo
          ? 0
          : Number(montosValue.montoMinimo),
        monto_maximo: !montosValue.montoMaximo
          ? 99999999
          : Number(montosValue.montoMaximo),
      },
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

const mediosDePagoFormated = (pago) => {
  if (pago.medio_pago && !pago.detalles_orden) {
    return pago.medio_pago;
  } else if (!pago.medio_pago && !pago.detalles_orden) {
    return "---";
  } else if (!pago.medio_pago && pago.detalles_orden) {
    return pago.detalles_orden.map((a) => a.medio_pago).join(", ");
  }
};

// const mediosDePagoDeOrden = (arrayDetalles) => {
//   let arrayMediosDePago = arrayDetalles.map((a) => (
//     <>
//       {a.medio_pago}
//       <br />
//     </>
//   ));
//   return arrayMediosDePago;
// };

const isDisabled = (mov) => {
  if (
    mov.tipo_movimiento === "Pagos de Comisiones" ||
    mov.tipo_movimiento === "Devolución" ||
    mov.tipo_movimiento === "Pagos de Venta"
  )
    return true;
};

const responsableDeOrden = (pago) => {
  let fullName = "";
  if (pago.responsable_carga) {
    fullName = `${pago.responsable_carga.nombre} ${pago.responsable_carga.apellido}`;
    return fullName;
  } else if (pago.responsable_retiro) {
    fullName = `${pago.responsable_retiro.nombre} ${pago.responsable_retiro.apellido}`;
    return fullName;
  } else if (pago.responsable_pago_cliente) {
    fullName = `${pago.responsable_pago_cliente.nombre} ${pago.responsable_pago_cliente.apellido}`;
    return fullName;
  }
};

const motivoPago = (pago) => {
  if (pago.venta && !pago.venta.has_factura && pago.carga_saldo) {
    return `${pago.tipo_movimiento} asociada a CI ${pago.venta.idVenta}`;
  } else if (pago.venta && pago.venta.has_factura && pago.carga_saldo) {
    return `${pago.tipo_movimiento} asociada a Factura ${pago.factura}`;
  } else {
    return pago.tipo_movimiento || "NA";
  }
};
const obtenerDescripcion = (movimiento) => {
  if (movimiento.tipo_registro === "orden_carga_saldo") {
    return "Carga de saldo";
  } else if (movimiento.tipo_registro === "orden_retiro_saldo") {
    return "Retiro de saldo";
  } else if (movimiento.tipo_registro === "pago_cliente") {
    return "Pago de cliente";
  } else if (movimiento.tipo_registro === "conciliacion_ventas") {
    return "Conciliación de ventas";
  }
};
export function formatPagos(
  mov,
  printPdf,
  deletePago,
  config,
  handleOpenModalCargarSaldo,
  handleOpenModalDetalleOrden,
  printPdfOrden,
  onDeletePagoConciliacion,
  printPDFConciliacion,
  updateInformacionResumen,
) {
  return mov.map((pago) => ({
    fecha: moment(pago.fechaHora).format("DD-MM-YYYY HH:mm"),
    monto: `$${parseFloat(pago.monto).toLocaleString("es-AR")}`,
    descripcion: obtenerDescripcion(pago),
    tipo: (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        {mediosDePagoFormated(pago)}
      </div>
    ),
    observacion: pago.observacion || "---",
    motivo: motivoPago(pago),
    responsable:
      pago.responsable_carga ||
      pago.responsable_retiro ||
      pago.responsable_pago_cliente
        ? responsableDeOrden(pago)
        : "---",
    estado: pago.fecha_anulacion ? "Anulado" : "Vigente",
    print: (
      <OptionsPrint
        mov={pago}
        printPdf={printPdf}
        deletePago={deletePago}
        config={config}
        handleOpenModalCargarSaldo={handleOpenModalCargarSaldo}
        handleOpenModalDetalleOrden={handleOpenModalDetalleOrden}
        printPdfOrden={printPdfOrden}
        onDeletePagoConciliacion={onDeletePagoConciliacion}
        printPDFConciliacion={printPDFConciliacion}
        updateInformacionResumen={updateInformacionResumen}
      />
    ),
  }));
}

const OptionsPrint = ({
  mov,
  printPdf,
  deletePago,
  config,
  handleOpenModalCargarSaldo,
  handleOpenModalDetalleOrden,
  printPdfOrden,
  onDeletePagoConciliacion,
  printPDFConciliacion,
  updateInformacionResumen,
}) => {
  return (
    <MenuB>
      <OpcionVerDetalles
        movimiento={mov}
        handleOpenModalDetalleOrden={handleOpenModalDetalleOrden}
        onDeletePagoConciliacion={onDeletePagoConciliacion}
      />
      {!mov.tipo_registro.includes("conciliacion_ventas") && (
        <OpcionImprimirComprobante
          movimiento={mov}
          config={config}
          printPdf={printPdf}
          printPdfOrden={printPdfOrden}
        />
      )}
      <OpcionAnularMovimiento
        movimiento={mov}
        deletePago={deletePago}
        updateInformacionResumen={updateInformacionResumen}
      />
      <RehacerConciliacionVentas movimiento={mov} />

      {mov.conciliacion_ventas_id &&
        mov.tipo_registro.includes("conciliacion_ventas") && (
          <OpcionesImprimirConciliacion
            movimiento={mov}
            config={config}
            printPdf={printPdf}
            printPDFConciliacion={printPDFConciliacion}
          />
        )}

      <OpcionImprimirConciliacionVentasAsociada
        movimiento={mov}
        printPDFConciliacion={printPDFConciliacion}
      />

      {mov.medio_pago === null &&
        !mov.tipo_registro.includes("orden") &&
        !mov.tipo_registro.includes("conciliacion") && (
          <MenuItem onClick={() => handleOpenModalCargarSaldo(mov)}>
            Generar contramovimiento
          </MenuItem>
        )}
    </MenuB>
  );
};

const AnularConciliacionVentas = ({
  movimiento,
  onAnularConciliacionVentas,
}) => {
  const [opened, { toggle, open, close }] = useDisclosure(false);

  return (
    <>
      <MenuItem onClick={() => toggle()}>
        <Box style={{ width: "100%" }}>Anular</Box>
      </MenuItem>
      {opened && (
        <ModalAnularConciliacionVentas
          open={opened}
          // onDeletePagoConciliacion={onDeletePagoConciliacion}
          conciliacion_ventas_id={movimiento.conciliacion_ventas_id}
          toggle={toggle}
          onAnularConciliacionVentas={onAnularConciliacionVentas}
        />
      )}
    </>
  );
};

const RehacerConciliacionVentas = ({ movimiento }) => {
  const [opened, { toggle, open, close }] = useDisclosure(false);

  if (!movimiento.fecha_anulacion || !movimiento.puede_rechear_conciliacion) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={() => toggle()}>
        <Box style={{ width: "100%" }}>Rehacer conciliación</Box>
      </MenuItem>
      {opened && (
        <ModalRehacerConciliacion
          open={opened}
          toggle={toggle}
          conciliacion_ventas_id={movimiento.conciliacion_ventas_id}
        />
      )}
    </>
  );
};

const OpcionAnularMovimiento = ({
  movimiento,
  deletePago,
  updateInformacionResumen,
}) => {
  if (
    !movimiento.fecha_anulacion &&
    !isDisabled(movimiento) &&
    !movimiento.tipo_registro.includes("conciliacion")
  )
    return (
      <MenuItem onClick={() => deletePago(movimiento)}>
        {/* <TooltipWithoutIcon title="Los pagos de venta, devoluciones o pagos de comisiones no se pueden anular"> */}
        <Box style={{ width: "100%" }}>Anular</Box>
        {/* </TooltipWithoutIcon> */}
      </MenuItem>
    );

  if (
    !movimiento.fecha_anulacion &&
    movimiento.tipo_registro.includes("conciliacion")
  )
    return (
      <AnularConciliacionVentas
        movimiento={movimiento}
        onAnularConciliacionVentas={updateInformacionResumen}
      />
    );

  return null;
};

const OpcionVerDetalles = ({
  movimiento,
  handleOpenModalDetalleOrden,
  onDeletePagoConciliacion,
}) => {
  const [opened, { toggle, open }] = useDisclosure();

  return (
    <>
      {movimiento.detalles_orden &&
        !movimiento.tipo_registro.includes("conciliacion") && (
          <MenuItem onClick={() => handleOpenModalDetalleOrden(movimiento)}>
            Ver detalles
          </MenuItem>
        )}
      {!movimiento.detalles_orden &&
        movimiento.tipo_registro.includes("conciliacion") && (
          <MenuItem onClick={open}>Ver detalles</MenuItem>
        )}
      {opened && (
        <ModalDetalleConciliacionVentas
          onDeletePagoConciliacion={onDeletePagoConciliacion}
          conciliacion_ventas_id={movimiento.conciliacion_ventas_id}
          toggle={toggle}
        />
      )}
    </>
  );
};

const OpcionImprimirConciliacionVentasAsociada = ({
  movimiento,
  printPDFConciliacion,
}) => {
  const [opened, { open, close }] = useDisclosure();
  const [formatoSeleccionado, setFormatoSeleccionado] = React.useState(null);

  const handleImprimirComprobante = () => {
    const opcionesImpresion = {
      A4: "conciliacionVentasA4",
      ticket: "conciliacionVentasTicket",
      WPA4: "conciliacionVentasWPA4",
    };
    let tipoImpresionPDF = opcionesImpresion[formatoSeleccionado];

    printPDFConciliacion(movimiento, tipoImpresionPDF);

    close();
    setFormatoSeleccionado(null);
  };

  if (
    movimiento.tipo_registro.includes("orden") &&
    movimiento.conciliacion_ventas_id
  )
    return (
      <>
        <MenuItem onClick={open}>Imprimir Conciliación</MenuItem>
        {opened && (
          <Dialog open={true} onClose={() => {}} maxWidth="sm" fullWidth>
            <DialogTitle>
              Imprimir comprobante de conciliacion asociada
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Elige en que formato quieres imprimir el comprobante o si desea
                enviarlo por whatsapp
              </DialogContentText>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <ButtonFormato
                    tipo="ticket"
                    click={() => setFormatoSeleccionado("ticket")}
                    format={formatoSeleccionado}
                    message="Ticket"
                    icon="print"
                  />
                </Grid>
                <Grid item>
                  <ButtonFormato
                    tipo="A4"
                    click={() => setFormatoSeleccionado("A4")}
                    format={formatoSeleccionado}
                    message="A4"
                    icon="print"
                  />
                </Grid>
                <Grid item>
                  <ButtonFormato
                    tipo="WPA4"
                    click={() => setFormatoSeleccionado("WPA4")}
                    format={formatoSeleccionado}
                    message="Enviar por Whatsapp"
                    icon="whatsapp"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonCancelar message="Cancelar" click={close} />
              <ButtonAceptar
                message="Aceptar"
                disabled={formatoSeleccionado === null}
                click={handleImprimirComprobante}
              />
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  else return null;
};

const OpcionImprimirComprobante = ({
  movimiento,
  config,
  printPdf,
  printPdfOrden,
}) => {
  const [opened, { open, close }] = useDisclosure();
  const [formatoSeleccionado, setFormatoSeleccionado] = React.useState(null);

  const handleImprimirComprobante = () => {
    if (formatoSeleccionado === "ticket") {
      if (movimiento.tipo_registro.includes("orden")) {
        printPdfOrden(
          movimiento,
          movimiento.carga_saldo === null
            ? "ticketPagoDeuda"
            : "billeteraTicket",
        );
      } else {
        printPdf(
          movimiento,
          movimiento.carga_saldo === null
            ? "ticketPagoDeuda"
            : "billeteraTicket",
        );
      }
    }
    if (formatoSeleccionado === "A4") {
      if (movimiento.tipo_registro.includes("orden")) {
        printPdfOrden(
          movimiento,
          movimiento.carga_saldo === null ? "A4PagoDeuda" : "billeteraA4",
        );
      } else {
        printPdf(
          movimiento,
          movimiento.carga_saldo === null ? "A4PagoDeuda" : "billeteraA4",
        );
      }
    }
    if (formatoSeleccionado === "A4Ahorro") {
      if (movimiento.tipo_registro.includes("orden")) {
        printPdfOrden(
          movimiento,
          movimiento.carga_saldo === null ? "A4Ahorro" : "billeteraA4Ahorro",
        );
      } else {
        printPdf(
          movimiento,
          movimiento.carga_saldo === null ? "A4Ahorro" : "billeteraA4Ahorro",
        );
      }
    }
    close();
    setFormatoSeleccionado(null);
  };

  return (
    <>
      <MenuItem onClick={open}>Imprimir Comprobante</MenuItem>
      {opened && (
        <Dialog open={true} onClose={() => {}} maxWidth="sm">
          <DialogTitle>{`Imprimir comprobante de ${obtenerDescripcion(
            movimiento,
          )}`}</DialogTitle>
          <DialogContent>
            <Box
              style={{
                padding: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography variant="body2">
                Elegí en qué formato preferís imprimir el comprobante
              </Typography>
            </Box>
            <Box
              style={{
                padding: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 8,
              }}
            >
              {config && config.en_ticket && (
                <ButtonFormato
                  tipo={"ticket"}
                  click={() => setFormatoSeleccionado("ticket")}
                  message="Ticket"
                  icon="receipt"
                  format={formatoSeleccionado}
                />
              )}

              {config && config.en_a4 && (
                <ButtonFormato
                  tipo={"A4"}
                  click={() => setFormatoSeleccionado("A4")}
                  format={formatoSeleccionado}
                  message="A4"
                  icon="print"
                />
              )}
              {config &&
                config.ahorro_papel &&
                !movimiento.tipo_registro.includes("conciliacion") && (
                  <ButtonFormato
                    tipo={"A4Ahorro"}
                    click={() => setFormatoSeleccionado("A4Ahorro")}
                    message="A4 ahorro"
                    icon="print"
                    format={formatoSeleccionado}
                  />
                )}
            </Box>
          </DialogContent>
          <DialogActions>
            <ButtonCancelar message={"Cancelar"} click={close} />
            <ButtonAceptar
              message={"Aceptar"}
              disabled={formatoSeleccionado === null}
              click={handleImprimirComprobante}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const OpcionesImprimirConciliacion = ({ movimiento, printPDFConciliacion }) => {
  const handleImprimirComprobante = (formato) => {
    const opcionesImpresion = {
      A4: "conciliacionVentasA4",
      ticket: "conciliacionVentasTicket",
      WPA4: "conciliacionVentasWPA4",
    };
    let tipoImpresionPDF = opcionesImpresion[formato];

    printPDFConciliacion(movimiento, tipoImpresionPDF);
  };

  return (
    <>
      <MenuItem onClick={() => handleImprimirComprobante("A4")}>
        Imprimir A4
      </MenuItem>
      <MenuItem onClick={() => handleImprimirComprobante("ticket")}>
        Imprimir ticket
      </MenuItem>
      <MenuItem onClick={() => handleImprimirComprobante("WPA4")}>
        Enviar por WhatsApp
      </MenuItem>
    </>
  );
};
