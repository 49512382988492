import React, { useState } from "react";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import TablaArticulos from "./TablaArticulos/index";
import { formatDataDuplicados } from "./TablaArticulos/utils";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

export default function ListaDetallesArticulos({
  useDataDuplicados,
  filters,
  useStock,
}) {
  return (
    <>
      <Grid container spacing={2} style={{ paddingTop: 8 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: 0 }}>
          <label htmlFor="">
            <span className="fontBold">
              {`Código ${filters.codigo === 0 ? "artículo" : "original"} : `}
            </span>
            {filters.codigo === 0
              ? useDataDuplicados.artSelected.codProveedor
              : useDataDuplicados.artSelected.codOriginal}
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TablaArticulos
            loading={useDataDuplicados.loading}
            items={formatDataDuplicados(
              filters,
              useDataDuplicados.duplicados,
              useStock,
            )}
            filters={filters}
            pagination={useDataDuplicados.pagination}
            setPagination={useDataDuplicados.setPagination}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {useDataDuplicados.duplicados.length == 2 &&
            useStock.artCorrecto &&
            useStock.artEliminados.length == 1 && (
              <Grid container alignItems="center">
                <FormControlLabel
                  className="m-0"
                  control={
                    <Switch
                      color="primary"
                      checked={useStock.descripcion != null}
                      onChange={() => useStock.handleChangeDescripcion()}
                      name="mantener_descripcion"
                    />
                  }
                  label={<div>Mantener descripción del repuesto duplicado</div>}
                />
                <TooltipMoreInfoMaterial
                  position={"right"}
                  titleTooltip={
                    <h6
                      style={{
                        fontSize: 15,
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Al activar esta opción, el código auxiliar del repuesto
                      eliminado se asignará al repuesto que elegiste como
                      correcto.
                    </h6>
                  }
                  color={"blue"}
                />
                <FormControlLabel
                  className="m-0"
                  control={
                    <Switch
                      color="primary"
                      checked={useStock.auxiliar != null}
                      onChange={() => useStock.handleChangeAuxiliar()}
                      name="mantener_auxiliar"
                    />
                  }
                  label={
                    <div>
                      Mantener el código auxiliar del repuesto duplicado
                    </div>
                  }
                />
                <TooltipMoreInfoMaterial
                  position={"right"}
                  titleTooltip={
                    <h6
                      style={{
                        fontSize: 15,
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                    >
                      Al activar esta opción, se conservará la descripción del
                      repuesto eliminado en el repuesto que elegiste como
                      correcto.
                    </h6>
                  }
                  color={"blue"}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </>
  );
}
